<template>
  <div class="">
    <el-form-item :prop="prop" :label="label">
      <el-row class="yihuang" :gutter="13">
        <el-col :span="7">
          <el-upload
            drag
            class="uplode"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeUpload"
          >
            <div
              class="uplode_text"
              :class="system.isPhone ? 'wapfont12' : 'font12'"
            >
              <img v-if="imageUrl" :src="imageUrl" class="uplode_icon" />
              <span> {{ $t("applyJoinUs.uplodeText.text1") }}</span>
              <span class="red"> {{ $t("applyJoinUs.uplodeText.text2") }}</span>
            </div>
          </el-upload>
        </el-col>
        <el-col class="red" :span="17">
          {{ $t("applyJoinUs.uplodeText.text3") }}
        </el-col>
      </el-row>
      <div v-if="fileName" class="tip_left">
        {{ fileName }}
      </div>
    </el-form-item>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  props: ["value", "prop", "inputType", "p", "keyName", "label"],
  components: {},
  data() {
    return {
      lodingData: {},
      fileName: "",
      imageUrl: require("@/assets/img/apply/11651611730720_ 1@2x.png"),
      info: {},
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [
        {
          name: "food.jpeg",
          url:
            "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
        },
        {
          name: "food2.jpeg",
          url:
            "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
        }
      ],

      valueData: this.value,
      new_inputType: this.inputType
    };
  },

  computed: {},
  methods: {
    openFullScreen2() {
      this.lodingData = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeUpload(file) {
      let type =
        file.type ||
        file.name.split(".")[file.name.split(".").length - 1].toLowerCase();
      console.log(type);
      const allow = [
        "image/jpeg",
        "image/png",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/kswps",
        "application/x-zip-compressed",
        "7z",
        "rar",
        "application/pdf",
        "text/plain"
      ];
      const isJPG = allow.indexOf(type) > -1;
      console.log(isJPG);
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error(
          "只支持上传JPG、Png图片或excel、word、txt、pdf、zip、rar、7z的文件"
        );
        return false;
      }

      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return false;
      }
      this.openFullScreen2();
      this.uploading = true;
      const formData = new FormData();
      // const data = {
      //   kind: "websiteQualification"
      // };
      formData.append("file", file);
      formData.append("kind", "websiteQualification");
      formData.append("sourceId", 1);
      this.$request("/api/front/daemon/files/upload", {
        method: "POST",
        data: formData,
        requestType: "form"
      })
        .then(res => {
          this.lodingData.close();
          console.log(res);
          this.uploading = false;
          if (res.code === 1) {
            this.fileName = file.name;
            this.change(res.data.linkUrl);
          }
        })
        .catch(err => {
          this.lodingData.close();
          console.log(err);
          this.uploading = false;
        });
      return false;
    },
    change(value) {
      console.log(value);
      this.$emit("uplodeChange", {
        [this.keyName]: value
      });
    },
    changeInputTypeEvent() {
      this.new_inputType == "text"
        ? (this.new_inputType = "password")
        : (this.new_inputType = "text");
    }
  },
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
/deep/.yihuang {
  display: flex;

  overflow: hidden;
  .el-col-7 {
    width: auto;
  }
}
/deep/.uplode {
  border: 2px solid #f0f0f0;
  width: 213px;
  height: 50px;
  .el-upload-dragger {
    width: 100%;
    height: 100%;
    border: 1px none #d9d9d9;
  }
  .uplode_icon {
    width: 19px;
    height: 14px;
    display: flex;
    flex-shrink: 0;
  }
  .el-upload--picture-card {
    height: 100%;
    width: 100%;
  }
  .el-upload {
    width: 100%;
    height: 100%;
  }
}
.red {
  color: rgba(201, 74, 76, 1);
  align-items: center;
  display: flex;
  line-height: 20px;
}
.uplode_text {
  line-height: 20px;
  > img {
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: black;
}
.tip_left {
  float: left;
  text-align: left;
  line-height: 14px;
  font-size: 12px;
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.5);
  word-break: break-all;
}
</style>
