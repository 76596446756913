<template>
  <div class="all">
    <top-menu-bar
      v-if="!system.isPhone"
      :curScroll="curScroll"
      @curIndex="curIndex"
    />
    <bar-wap
      v-if="system.isPhone"
      class="wap_bar_menu"
      :curScroll="curScroll"
      @curIndex="curIndex"
    />
    <div v-if="!system.isPhone">
      <img
        class="dailitupian"
        :src="
          $t('languages') == 'EN'
            ? require('@/assets/img/apply/lijiruzhu_en.jpg') +
              `?t=${new Date().getTime()}`
            : require('@/assets/img/apply/lijiruzhu.png') +
              `?t=${new Date().getTime()}`
        "
      />
    </div>
    <div :class="system.isPhone ? 'wap_form' : 'form'">
      <apply-form></apply-form>
    </div>
    <bottom-det v-if="!system.isPhone" />
    <!-- <wap-bottom v-if="system.isPhone" /> -->
  </div>
</template>
<script>
import applyForm from "./components/applyForm";
import TopMenuBar from "@/views/publicComponents/TopMenuBar";
import BottomDet from "@/views/publicComponents/BottomDet";
// import WapBottom from "@/views/publicComponents/WapBottom";
import BarWap from "@/views/publicComponents/BarWap";
export default {
  components: {
    TopMenuBar,
    applyForm,
    BottomDet,
    // WapBottom,
    BarWap
  },
  data() {
    return {
      curScroll: 0,
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        region: "",
        type: ""
      }
    };
  },
  methods: {
    curIndex(index) {
      this.curScroll = index;
    }
  }
};
</script>
<style lang="less" scoped>
.all {
  // min-width: 750px;
}
.form {
  position: relative;
  width: calc(100vw * 127.2 / 192);
  // min-width: 750px;
  background-color: white;
  height: auto;
  margin: auto;
  margin-top: -150px;
  padding-top: 44px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 36px;
  margin-bottom: 50px;
  min-width: 620px;
}
.dailitupian {
  width: 100%;
}
// 移动端样式
.wap_form {
  text-align: left;
  margin-top: calc(100vw * 6 / 37.5);
}
.wap_header {
  position: relative;
  margin-top: calc(100vw * 13 / 37.5);
  height: calc(100vw * 5 / 37.5);
  margin-top: calc(100vw * 8 / 37.5);
  font-family: "FF1";
  color: rgba(25, 119, 251, 1);
  margin-right: 20px;
  margin-left: 20px;
  text-align: left;
}
</style>
